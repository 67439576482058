
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// components
import { Item } from '../../storybook/Aside/Item/Item';

interface Props {
  is_mobile?: boolean,
}

export const Functions = ({
  is_mobile = false,
}: Props) => {
  const { t, i18n } = useTranslation()
  const {
    country_code,
  } = useParams()

  const apps = [
    {
      'name': t('Taiwan'),
      'path': `/${i18n.language}/${country_code}`,
    },
  ]

  return apps.map((app, index) => {
    return (
      <Item
        key={ index }
        name={ app['name'] }
        path={ app['path'] }
        theme='rose'
        is_mobile={ is_mobile }
      />
    )
  })
}