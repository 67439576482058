import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import axios from 'axios'

// components
import { Lottery } from '../storybook/Lottery'

// types
import {
  LotteryType,
} from '../types'

// preferences
import {
  get_user,
  set_user,
} from '../preferences'

import { lotteries } from '../lotteries/tw'

export function Index() {
  const {
    country_code,
  } = useParams()

  // states
  const [lucky_number, set_lucky_number] = useState<number[]>()
  const [life_number, set_life_number] = useState<number>()
  const [result, set_result] = useState<number[]>()
  const [result_2, set_result_2] = useState<number|undefined>()
  const [showing_lottery, set_showing_lottery] = useState<LotteryType>()

  const [min_number, set_min_number] = useState<number>()
  const [max_number, set_max_number] = useState<number>()
  const [total, set_total] = useState<number>()

  const [first_name, set_first_name] = useState<string>()
  const [last_name, set_last_name] = useState<string>()
  const [sex, set_sex] = useState<string>()
  const [birth, set_birth] = useState<string>()

  useEffect(() => {
    const async_user = async () => {
      const user_json = await get_user()
      if (user_json) {
        const user = JSON.parse(user_json)
        set_last_name(user['last_name'])
        set_first_name(user['first_name'])
        set_sex(user['sex'])
        set_birth(user['birth'])
      }
    }

    async_user()
  }, [])

  const on_click = (genre: string, min_number: number, max_number: number, total: number) => {
    set_showing_lottery(lotteries.find(lottery => lottery.name === genre))

    set_min_number(min_number)
    set_max_number(max_number)
    set_total(total)

    type ResponseType = {
      data: {
        result: number[],
        result_2: number,
        lucky_number: number[],
        life_number: number,
      }
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/en/${country_code?.toLocaleLowerCase()}/api/yield_number`, {
        genre: genre,
        min_number: min_number,
        max_number: max_number,
        total: total,
        last_name: last_name,
        first_name: first_name,
        sex: sex,
        birth: birth,
      })
      .then((response: ResponseType) => {
        set_result(response.data.result)
        set_result_2(response.data.result_2)
        set_lucky_number(response.data.lucky_number)
        set_life_number(response.data.life_number)
      })
  }

  const Form = () => {
    const last_name_changed = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      set_last_name(e.target.value)

      const new_user = {
        last_name: e.target.value,
        first_name: first_name,
        sex: sex,
        birth: birth,
      }
      set_user(JSON.stringify(new_user))
    }

    const first_name_changed = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      set_first_name(e.target.value)

      const new_user = {
        last_name: last_name,
        first_name: e.target.value,
        sex: sex,
        birth: birth,
      }
      set_user(JSON.stringify(new_user))
    }

    const sex_changed = (e: React.ChangeEvent<HTMLSelectElement>) => {
      set_sex(e.target.value)

      const new_user = {
        last_name: last_name,
        first_name: first_name,
        sex: e.target.value,
        birth: birth,
      }
      set_user(JSON.stringify(new_user))
    }

    const birth_changed = (e: React.ChangeEvent<HTMLInputElement>) => {
      set_birth(e.target.value)

      const new_user = {
        last_name: last_name,
        first_name: first_name,
        sex: sex,
        birth: e.target.value,
      }
      set_user(JSON.stringify(new_user))
    }

    return (
      <form>
        <div className="flex flex-row items-center flex-wrap mx-auto mt-2 mb-5">
          <div className="flex flex-row items-center flex-wrap mt-2 mb-2 ml-2">
            <div className="mr-1">性:</div>
            <input
              type='text'
              name="last_name"
              className="block px-3 py-2 bg-white border border-solid border-black dark:border-slate-300"
              defaultValue={last_name}
              onBlur={last_name_changed}
            />
          </div>
          <div className="flex flex-row items-center flex-wrap mt-2 mb-2 ml-2">
            <div className="mr-1">名:</div>
            <input
              type='text'
              name="first_name"
              className="block px-3 py-2 bg-white border border-solid border-black dark:border-slate-300"
              defaultValue={first_name}
              onBlur={first_name_changed}
            />
          </div>

          <div className="flex flex-row items-center flex-wrap mt-2 mb-2 ml-2">
            <div className="mr-1">性別:</div>
            <select
              className="block py-2 bg-white border border-solid border-black dark:border-slate-300"
              name="sex"
              defaultValue={sex}
              onChange={sex_changed}
            >
              <option value='male'>男生</option>
              <option value='female'>女生</option>
              <option value='other'>其他</option>
            </select>
          </div>

          <div className="flex flex-row items-center flex-wrap mt-2 mb-2 ml-2">
            <div className="mr-1">生日:</div>
            <input
              type='date'
              name="birth"
              className="block px-3 py-2 bg-white border border-solid border-black dark:border-slate-300"
              defaultValue={birth}
              onChange={birth_changed}
            />
          </div>
        </div>
      </form>
    )
  }

  const Result = useCallback(() => {
    if (showing_lottery) {
      const Infos = () => {
        return showing_lottery.infos.map((info, index) => {
          return (
            <div
              key={index}
              className="flex flex-row items-center flex-wrap my-2"
            >
              <div className="mr-2">{ info[0] }</div>
              <div>{ info[1] }</div>
            </div>
          )
        })
      }

      return (
        <div className=''>
          <div className='flex flex-col justify-start mb-3 lg:flex-row lg:items-center lg:justify-center p-2.5'>
            {!result_2 && (
              <>
                <h2 className='text-rose-800 dark:text-rose-400 mr-1.5'>您要買的號碼是</h2>
                <h1 className=''>{result?.join(', ')}</h1>
              </>
            )}

            {result_2 && (
              <>
                <div className='flex flex-row'>
                  <h2 className='text-rose-800 dark:text-rose-400 mr-1.5'>第一區</h2>
                  <h1 className=''>{result?.join(', ')}</h1>
                </div>

                <div className='flex flex-row lg:ml-3.5'>
                  <h2 className='text-rose-800 dark:text-rose-400 mr-1.5'>第二區</h2>
                  <h1 className=''>{result_2}</h1>
                </div>
              </>
            )}
          </div>
          <div className='flex flex-col flex-wrap mb-10 p-2.5 w-full lg:flex-row'>
            <img src={showing_lottery.image} className='block w-full bg-white border border-solid border-black dark:border-white lg:w-1/2' />

            <div className='grow lg:ml-5'>
              <div className="flex flex-row items-center flex-wrap my-2">
                <div className='mr-2 text-amber-800 dark:text-amber-400'>您的生命靈數:</div>
                <div className=''>{life_number}</div>
              </div>
              <div className="flex flex-row items-center flex-wrap my-2">
                <div className='mr-2 text-orange-800 dark:text-orange-400'>您的幸運號碼:</div>
                <div className=''>{lucky_number?.join(', ')}</div>
              </div>

              <Infos />

              <button
                type="submit"
                className="block w-full cursor-pointer text-center border border-solid border-black text-white bg-indigo-800 dark:bg-indigo-600 hover:bg-indigo-900 dark:hover:bg-indigo-800 px-3 py-2 rounded mt-5"
                onClick={() => { on_click(showing_lottery.name, min_number??0, max_number??0, total??0) }}
              >
                重新
              </button>
            </div>
          </div>
        </div>
      )
    }
  }, [showing_lottery, result])

  const Lotteries = () => {
    return lotteries.map((lottery, index) => {
      return (
        <Lottery
          key={index}
          lottery={lottery}
          on_click={on_click}
        />
      )
    })
  }

  return (
    <section className="p-2.5 lg:p-5">
      <Form />

      <Result />

      <div className="w-full grid gap-x-3 gap-y-6 lg:grid-cols-2 2xl:grid-cols-3">
        <Lotteries />
      </div>
    </section>
  )
}
