
import React from 'react'
import { useTranslation } from 'react-i18next'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

// redux
import { useAppDispatch } from '../app/hooks'

// aside slice
import {
  update_mobile_is_visible,
} from '../slice/asideSlice'

export const Header = () => {
  const { t } = useTranslation()

  // redux
  const dispatch = useAppDispatch()

  return (
    <header className='flex justify-between items-center min-h-14 py-2 px-1.5 bg-gray-300 dark:bg-header-bg-dark rounded w-full'>
      <h1 className='w-full ml-4 lg:ml-2 mr-2 lg:text-center'>{ t('Lottery number maker') }</h1>

      <button
        onClick={ () => { dispatch(update_mobile_is_visible(true)) } }
        className="lg:hidden"
      >
        <FontAwesomeIcon
          icon={ faBars }
          className="w-10 h-10 text-text-black dark:text-white hover:text-yellow-900 dark:hover:text-yellow-300 mr-2.5 cursor-pointer"
        />
      </button>
    </header>
  )
}

export default Header
