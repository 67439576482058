
// types
import { LotteryType } from '../types'

export const lotteries: LotteryType[] = [
  {
    'name': '威力彩',
    'image': '/images/wei_li_cai.jpeg',
    'infos': [
      ['頭獎', '200,000,000 以上'],
      ['費用', '每注100元'],
      ['總中獎率', '約1/9'],
      ['頭獎中獎率', '約1/2,209萬'],
    ],
    'min_number': 1,
    'max_number': 38,
    'total': 6,
    'options': []
  },
  {
    'name': '大樂透',
    'image': '/images/da_le_tou.jpeg',
    'infos': [
      ['頭獎', '100,000,000 以上'],
      ['費用', '每注50元'],
      ['總中獎率', '約1/32'],
      ['頭獎中獎率', '約1/1,398萬'],
    ],
    'min_number': 1,
    'max_number': 49,
    'total': 6,
    'options': []
  },
  {
    'name': '雙贏彩',
    'image': '/images/shuang_ying_cai.png',
    'infos': [
      ['頭獎', '15,000,000元'],
      ['費用', '每注100元'],
      ['總中獎率', '約1/26'],
      ['頭獎中獎率', '約1/135萬'],
    ],
    'min_number': 1,
    'max_number': 24,
    'total': 12,
    'options': []
  },
  {
    'name': '今彩539',
    'image': '/images/jin_cai_539.jpeg',
    'infos': [
      ['頭獎', '8,000,000元'],
      ['費用', '每注50元'],
      ['總中獎率', '約1/9'],
      ['頭獎中獎率', '約1/58萬'],
    ],
    'min_number': 1,
    'max_number': 39,
    'total': 5,
    'options': []
  },
  {
    'name': '3星彩',
    'image': '/images/san_xing_cai.png',
    'infos': [
      ['頭獎', '500倍'],
      ['費用', '每注25元起'],
      ['', ''],
      ['正彩中獎率', '1/1,000'],
      ['', ''],
      ['-組彩中獎率-', ''],
      ['3組採', '約1/333'],
      ['6組採', '約1/167'],
      ['', ''],
      ['對彩中獎率', '約1/50'],
    ],
    'min_number': 0,
    'max_number': 9,
    'total': 3,
    'options': []
  },
  {
    'name': '4星彩',
    'image': '/images/si_xing_cai.png',
    'infos': [
      ['頭獎', '5000倍'],
      ['費用', '每注25元起'],
      ['正彩中獎率', '1/10,000'],
      ['', ''],
      ['-組彩中獎率-', ''],
      ['4組採', '約1/2,500'],
      ['6組採', '約1/1,667'],
      ['12組採', '約1/833'],
      ['24組採', '約1/417'],
      ['', ''],
      ['對彩中獎率', '約1/50'],
    ],
    'min_number': 0,
    'max_number': 9,
    'total': 4,
    'options': []
  },
  {
    'name': '38樂合彩',
    'image': '/images/san_ba_le_he_cai.jpeg',
    'infos': [
      ['頭獎', '46,000倍'],
      ['費用', '每注25元起'],
      ['', ''],
      ['五合中獎率', '約1/83,657'],
      ['四合合中獎率', '約1/4,921'],
      ['三合中獎率', '約1/422'],
      ['二合中獎率', '約1/47'],
    ],
    'min_number': 1,
    'max_number': 38,
    'total': 0,
    'options': [['五合', 5], ['四合', 4], ['三合', 3], ['二合', 2]]
  },
  {
    'name': '49樂合彩',
    'image': '/images/si_jiu_le_he_cai.jpeg',
    'infos': [
      ['頭獎', '8,000倍'],
      ['費用', '每注25元起'],
      ['', ''],
      ['四合合中獎率', '約1/14,125'],
      ['三合中獎率', '約1/921'],
      ['二合中獎率', '約1/78'],
    ],
    'min_number': 1,
    'max_number': 49,
    'total': 0,
    'options': [['四合', 4], ['三合', 3], ['二合', 2]]
  },
  {
    'name': '39樂合彩',
    'image': '/images/san_jiu_le_he_cai.png',
    'infos': [
      ['頭獎', '8,500倍'],
      ['費用', '每注25元起'],
      ['', ''],
      ['四合中獎率', '約1/16,450'],
      ['三合中獎率', '約1/914'],
      ['二合中獎率', '約1/74'],
    ],
    'min_number': 1,
    'max_number': 39,
    'total': 0,
    'options': [['四合', 4], ['三合', 3], ['二合', 2]]
  },
  {
    'name': '賓果賓果樂翻天',
    'image': '/images/bing_guo.jpeg',
    'infos': [
      ['頭獎', '500,000倍'],
      ['費用', '每注25元起'],
    ],
    'min_number': 1,
    'max_number': 80,
    'total': 0,
    'options': [['10星', 10], ['9星', 9], ['8星', 8], ['7星', 7], ['6星', 6], ['5星', 5], ['4星', 4], ['3星', 3], ['2星', 2], ['1星', 1]],
  }
]