
import React from 'react'
// import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import {
  useEffect,
} from 'react'
import $ from 'jquery'
import { Preferences } from '@capacitor/preferences'
import {
  Routes,
  Route,
} from 'react-router-dom'
import {
  useNavigate,
} from 'react-router-dom'

import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { SplashScreen } from '@capacitor/splash-screen'

// css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// helmet
import {
  HelmetProvider
} from 'react-helmet-async'

// redux
import { Provider } from 'react-redux'
import { makeStore, AppStore } from '../app/store'

// components
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Aside from './Aside'
import AsideMobile from './AsideMobile'

const Layout = () => {
  // const { i18n } = useTranslation()
  const navigate = useNavigate()

  CapacitorUpdater.addListener('appReady', () => {
    SplashScreen.hide()
  })

  CapacitorUpdater.notifyAppReady()

  const storeRef = useRef<AppStore>()

  if (!storeRef.current) {
    storeRef.current = makeStore()
  }

  useEffect(() => {
    const pathname_split_by_slash = window.location.pathname.split('/')

    const has_language = /en|ja|zh-hant|zh-hans/.test(pathname_split_by_slash[1])
    const has_country_code = /JP|TW/.test(pathname_split_by_slash[2])

    if (!has_language || !has_country_code) {
      navigate(`/${has_language ? pathname_split_by_slash[1] : 'en'}/${has_country_code ? pathname_split_by_slash[2] : 'TW'}`)

      // for capacitor
      // const country_code: 'US' | 'JP' | 'TW' | 'CN' = 'TW'

      // const language_code = {
      //   US: 'en',
      //   JP: 'ja',
      //   TW: 'zh-hant',
      //   CN: 'zh-hans',
      // }

      // i18n.changeLanguage(language_code[country_code])
      // navigate(`/${language_code[country_code]}/${country_code}`)
    }

    const set_theme = async () => {
      const { value } = await Preferences.get({ key: 'theme' })

      if (value === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }

    set_theme()

    $('header').css('opacity', '0')
    $('aside').css('opacity', '0')
    $('main').css('opacity', '0')
    $('footer').css('opacity', '0')

    setTimeout( function () {
      $('header').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 200 )

    setTimeout( function () {
      $('aside').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 400 )

    setTimeout( function () {
      $('main').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 600 )

    setTimeout( function () {
      $('footer').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 800 )

    setTimeout( function () { 
      $('main').css(
        'min-height',
        (($(window).innerHeight() || 0) - ($('header').outerHeight() || 0) - ($('footer').outerHeight() || 0) - 4) + 'px'
      )
    }, 200)
  }, [])

  return (
    <HelmetProvider>
      <Provider store={ storeRef.current }>
        <Routes>
          <Route
            path='/:locale/:country_code/*'
            element={
              <>
                <div className='flex flex-row justify-center m-px gap-x-px min-h-screen text-text-black dark:text-white'>

                  <Aside />
                  <AsideMobile />

                  <div className='flex flex-col w-full lg:w-88/100 flex-grow gap-y-px'>
                    <Header />
                    <Main />
                    <Footer />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </Provider>
    </HelmetProvider>
  )
}

export default Layout