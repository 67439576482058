
import React from 'react'

// types
import { LotteryType } from '../types'

interface Props {
  lottery: LotteryType,
  on_click?: (genre: string, min_number: number, max_number: number, total: number) => void,
}

export const Lottery = ({
  lottery,
  on_click,
}: Props) => {

  const Infos = () => {
    return lottery.infos.map((info, index) => {
      return (
        <div
          key={index}
          className="flex flex-row items-center flex-wrap"
        >
          <div className="mr-1">{ info[0] }</div>
          <div>{ info[1] }</div>
        </div>
      )
    })
  }

  const Options = () => {
    return lottery.options.map((option, index) => {
      return (
        <button
          key={index}
          className="block mx-3 my-2 px-3 py-2 cursor-pointer text-center text-white bg-indigo-700 rounded hover:bg-indigo-900"
          onClick={() => { if (on_click) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            on_click(lottery.name, lottery.min_number, lottery.max_number, option[1])
          } }}
        >
          { option[0] }
        </button>
      )
    })
  }

  return (
    <div
      className={`w-full p-5 bg-white dark:bg-slate-200 text-gray-800 h-fit rounded hover:bg-slate-100 dark:hover:bg-slate-300 cursor-pointer border border-solid border-black`}
      onClick={() => { if (on_click && lottery.options.length === 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        on_click(lottery.name, lottery.min_number, lottery.max_number, lottery.total)
      } }}
    >
      <img src={ lottery.image } className="w-full mt-3 mb-3 bg-white border border-solid border-amber-900" />
      <Infos />

      <div className="flex flex-row items-center flex-wrap mt-3">
        <Options />
      </div>
    </div>
  )
}
