
import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

// components
import { PrivacyPolicy } from '../features/PrivacyPolicy'
import { Tos } from '../features/Tos'
import { Index } from '../features/Index'

const Main = () => {
  return (
    <main className="relative bg-slate-200 dark:bg-main-bg-dark rounded w-full flex-grow">
      <Routes>
        <Route path='/privacy_policy' element={ <PrivacyPolicy /> } />
        <Route path='/tos' element={ <Tos /> } />
        <Route path='/' element={ <Index /> } />
      </Routes>
    </main>
  )
}

export default Main