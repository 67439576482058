import React from 'react'
import { useTranslation } from 'react-i18next'
import { Preferences } from '@capacitor/preferences'

// components
import { Functions } from './Aside/Functions'
import { Languages } from './Aside/Languages'
import { Item } from '../storybook/Aside/Item/Item'

const Aside = () => {
  const { t } = useTranslation()

  return (
    <aside className='bg-sky-50 dark:bg-aside-bg-dark hidden lg:block text-center pb-24 w-0 lg:w-12/100 rounded'>
      <div className="flex flex-col justify-start items-stretch">
        <Functions />

        <Item
          name={ t('Change theme') }
          theme='lime'
          action='customize'
          onclick_action={ async () => {
            const { value } = await Preferences.get({ key: 'theme' })

            await Preferences.set({
              key: 'theme',
              value: value === 'dark' ? 'light' : 'dark',
            })

            window.location.reload()
          } }
        />

        <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>Langs</div>
        <Languages />

        {/* <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>{ t('Other sites') }</div>

        <Item
          name={ t('Twitter download ranking') }
          path={ `https://x.hamary.co/video_download_ranking/1` }
          theme='emerald'
          action='redirect_target_blank'
        /> */}

        <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>{ t('Games') }</div>
        <Item
          name='Death Run'
          path='https://backend.hamary.co/games/DeathRun'
          theme='violet'
          action='redirect_target_blank'
        />

      </div>
    </aside>
  )
}

export default Aside