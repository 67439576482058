
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'

// fontawesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faSquareXTwitter,
// } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const {
    country_code,
  } = useParams()

  return (
    <footer className='bg-blue-300 dark:bg-footer-bg-dark pt-7 pb-24 px-5 rounded w-full'>
      <div className="w-full flex flex-col lg:flex-row">
        <div>
          <div
            onClick={ () => {
              navigate(`/${i18n.language}/${country_code}/privacy_policy`)
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }}
            className='block w-fit hover:text-fuchsia-900 dark:hover:text-fuchsia-300 cursor-pointer'
          >
            { t('Privacy policy') }
          </div>

          <div
            onClick={ () => {
              navigate(`/${i18n.language}/${country_code}/tos`)
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }}
            className='block w-fit hover:text-fuchsia-900 dark:hover:text-fuchsia-300 cursor-pointer'
          >
            { t('TOS') }
          </div>
        </div>

        <div className="mt-4 lg:mt-0 lg:ml-10">
          <div className='w-fit border-0 border-b border-solid border-b-sky-600 dark:border-b-sky-300 px mb-1.5'>
            { t('Contact') }
          </div>

          <div className='flex flex-row items-center flex-wrap'>
            <div className='mr-2'>{ t('Email') }:</div>

            <a
              href='mailto:hamarynet@gmail.com'
              className='block underline hover:text-fuchsia-900 dark:hover:text-fuchsia-300 cursor-pointer'
              target="_blank"
            >
              hamarynet@gmail.com
            </a>

          </div>
        </div>
      </div>

      <div className='mt-6'>
        <div className='block'>&copy;Lottery number maker</div>

        <div className="flex flex-row gap-x-1.5 mt-3">
          {/* <a
            href="https://twitter.com/okazu_share"
            target='_blank'
            className='block cursor-pointer hover:text-orange-900 dark:hover:text-orange-300'
          >
            <FontAwesomeIcon
              icon={faSquareXTwitter}
              className='block w-10 h-10' />
          </a> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer
